<template>
  <transition appear name="fade">
    <div class="container p-0">
      <appHeader></appHeader>
      <appAdminSidebar></appAdminSidebar>
      <div v-if="isCreationLoading"  class="text-center m-4 bold">
        Creating...
      </div>
      <div v-if="isCreationError" class="text-center m-4 bold">
        Failed to create cards
      </div>
      <div v-if="createdCards.length > 0">
        <div class="card-container px-4">
          <h5>Created {{ totalNewCardsCount }} cards</h5>
          <p><strong>Awarded {{ totalNewCardsValue.toFixed(2) }} USD</strong></p>
        </div>
        <div class="card-container px-4">
          These users will get a gift-card email
          <table class="table">
            <thead>
              <tr>
                <th scope="col">User Name</th>
                <th scope="col">Email</th>
                <th scope="col">Value</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="card in createdCards" :key="card.code">
                <td>{{ card.userName }}</td>
                <td>{{ card.userEmail }}</td>
                <td>{{ card.currency }} {{ card.value }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <appFooter></appFooter>
    </div>
  </transition>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import AdminSidebar from '../AdminSidebar.vue'
import { mapState } from 'vuex'
import GiftcardService from "../../../services/GiftcardService"

export default {
  components: {
    appHeader: Header,
    appFooter: Footer,
    appAdminSidebar: AdminSidebar,
  },
  data () {
    return {
      createdCards: []
    }
  },
  computed: {
    ...mapState({
      preparedNewCards: state => state.admin.preparedNewCards,
      chosenTemplateId: state => state.admin.chosenTemplateId,
      currency: state => state.admin.currency,

      isCreationSuccessful: state => state.admin.status.createCards.initialized,
      isCreationLoading: state => state.admin.status.createCards.refreshing,
      isCreationError: state => state.admin.status.createCards.error,
    }),
    totalNewCardsCount: {
      get () {
        return this.createdCards.length
      }
    },
    totalNewCardsValue: {
      get () {
        return this.createdCards.reduce((accumulator, object) => {
          return (accumulator + object.value);
        }, 0)
      }
    },
  },
  methods: {
    emptyPreparedNewCards: function () {
      this.$store.dispatch('admin/emptyPreparedNewCards')
    },
    showError: function (message) {
      this.$bvModal.msgBoxOk(message, {
        size: 'sm',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
      })
    }
  },
  async created () {
    let requestBody = []
    for await (let card of this.preparedNewCards) {
      card.currency = card.currencyCode;
      card.templateId = this.chosenTemplateId;
      card.value = parseFloat(card.value);
      requestBody.push(card);
    }
    await GiftcardService.createGiftCardOrder(requestBody)
      .then(async (response) => {
        this.createdCards = response.data
        this.emptyPreparedNewCards()
      }).catch((e) => {
        this.showError('Unknown error')
      });
    /*this.$store.dispatch('admin/createCards', {
      cards: this.preparedNewCards,
      currency: this.currency,
      templateId: this.chosenTemplateId,
    }).then((resp) => {
          this.createdCards = resp
          this.emptyPreparedNewCards()
        },
        (err) => {
          this.showError('Unknown error')
        })
    .catch(() => {
      this.showError('Unknown error')
    })*/
  },
}
</script>

<style lang="scss">
</style>
