var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { appear: "", name: "fade" } }, [
    _c(
      "div",
      { staticClass: "container p-0" },
      [
        _c("appHeader"),
        _c("appAdminSidebar"),
        _vm.isCreationLoading
          ? _c("div", { staticClass: "text-center m-4 bold" }, [
              _vm._v(" Creating... ")
            ])
          : _vm._e(),
        _vm.isCreationError
          ? _c("div", { staticClass: "text-center m-4 bold" }, [
              _vm._v(" Failed to create cards ")
            ])
          : _vm._e(),
        _vm.createdCards.length > 0
          ? _c("div", [
              _c("div", { staticClass: "card-container px-4" }, [
                _c("h5", [
                  _vm._v("Created " + _vm._s(_vm.totalNewCardsCount) + " cards")
                ]),
                _c("p", [
                  _c("strong", [
                    _vm._v(
                      "Awarded " +
                        _vm._s(_vm.totalNewCardsValue.toFixed(2)) +
                        " USD"
                    )
                  ])
                ])
              ]),
              _c("div", { staticClass: "card-container px-4" }, [
                _vm._v(" These users will get a gift-card email "),
                _c("table", { staticClass: "table" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", { attrs: { scope: "col" } }, [
                        _vm._v("User Name")
                      ]),
                      _c("th", { attrs: { scope: "col" } }, [_vm._v("Email")]),
                      _c("th", { attrs: { scope: "col" } }, [_vm._v("Value")])
                    ])
                  ]),
                  _c(
                    "tbody",
                    _vm._l(_vm.createdCards, function(card) {
                      return _c("tr", { key: card.code }, [
                        _c("td", [_vm._v(_vm._s(card.userName))]),
                        _c("td", [_vm._v(_vm._s(card.userEmail))]),
                        _c("td", [
                          _vm._v(
                            _vm._s(card.currency) + " " + _vm._s(card.value)
                          )
                        ])
                      ])
                    }),
                    0
                  )
                ])
              ])
            ])
          : _vm._e(),
        _c("appFooter")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }